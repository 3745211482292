import { graphql } from "gatsby";
import React from "react";
import Layout from "components/Layout";
import NewsAndEventsTabBar from "../ducks/NewsAndEventsTabBar";
import { TAB_SLUGS } from "../consts/news-and-events";
import Article from "../widgets/Article";
import { usePaginatedContent } from "../utils/pagination";

export default function EventsPage({
  data: {
    allMarkdownRemark: { edges },
  },
}) {
  const content = edges.map(
    (
      {
        node: {
          frontmatter: {
            eventStartDate,
            eventEndDate,
            eventImage,
            eventSubtitle,
            title,
            slug,
          },
        },
      },
      index
    ) => (
      <Article
        key={`${title}-${index}`}
        article={[
          {
            startDate: eventStartDate,
            endDate: eventEndDate,
            subTitle: eventSubtitle,
            linkUrl: slug,
            image: eventImage,
            title,
          },
        ]}
      />
    )
  );

  const paginatedContent = usePaginatedContent({ content });

  return (
    <Layout title="Upcoming Events">
      <NewsAndEventsTabBar slug={TAB_SLUGS.UPCOMING_EVENTS} />
      {paginatedContent}
    </Layout>
  );
}

export const query = graphql`
  query upcomingEvents {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { glob: "**/upcoming-events/**" } }
      sort: { fields: frontmatter___eventStartDate, order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            uid
            title
            text
            slug
            eventStartDate(formatString: "MM/DD/YYYY")
            eventEndDate(formatString: "MM/DD/YYYY")
            eventSubtitle
            eventImage {
              extension
              publicURL
              childImageSharp {
                gatsbyImageData
              }
            }
            sections {
              ...CommonSectionFieldsFragment
              ...ArticleFragment
            }
          }
        }
      }
    }
  }
`;
